import { Link } from "gatsby"
import React, { Component } from "react"
import { closeNav } from "../utils/nav"
import { getScrollToId } from "../utils/scroll"

export class ScrollButton extends Component {
  handleClick = () => {
    const { onClick = null } = this.props
    getScrollToId(this.props.id)()
    closeNav()
    if (onClick) {
      onClick()
    }
  }

  render() {
    const { className, children, id, activeClassName } = this.props
    const linkToId = `/#${id}`
    return (
      <Link
        className={className}
        onClick={this.handleClick}
        to={linkToId}
        activeClassName={activeClassName}
      >
        {children}
      </Link>
    )
  }
}

export default ScrollButton
