import React, { Component } from "react"
import Modal from "./Modal"
import Toggle from "./Toggle"

export class Credits extends Component {
  render() {
    const modalId = "credits"
    const { parentClass } = this.props
    return (
      <>
        <Modal modalId={modalId} />
        <Toggle modalId={modalId} parentClass={parentClass} />
      </>
    )
  }
}

export default Credits
