import React, { Component } from "react"
import Close from "./Close"

export class Header extends Component {
  render() {
    const { titleId, title } = this.props
    return (
      <div className="modal-header">
        <h5 className="modal-title" id={titleId}>
          {title}
        </h5>
        <Close />
      </div>
    )
  }
}

export default Header
