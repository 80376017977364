import React, { Component } from "react"

import BaseModal from "../../../Modal"
import BaseHeader from "../../../Modal/Header/index"
import ModalCloseFooter from "../../../ModalCloseFooter"
import Item from "./Item"
import CreditsLink from "./Link"

export class Header extends Component {
  render() {
    return <BaseHeader title="Credits"></BaseHeader>
  }
}

export class Modal extends Component {
  render() {
    const { modalId } = this.props
    return (
      <BaseModal
        modalId={modalId}
        Header={Header}
        footer={<ModalCloseFooter />}
      >
        <ul className="text-left">
          <Item>
            <CreditsLink href="https://stories.freepik.com/nature">
              Illustration by Freepik Stories
            </CreditsLink>
          </Item>
          <Item>
            Icons made by{" "}
            <CreditsLink
              href="https://www.flaticon.com/authors/freepik"
              title="Freepik"
            >
              Freepik
            </CreditsLink>{" "}
            from{" "}
            <CreditsLink href="https://www.flaticon.com/" title="Flaticon">
              {" "}
              www.flaticon.com
            </CreditsLink>
          </Item>
          <Item>
            Icons made by{" "}
            <CreditsLink
              href="https://www.flaticon.com/authors/pixel-perfect"
              title="Pixel perfect"
            >
              Pixel perfect
            </CreditsLink>{" "}
            from{" "}
            <CreditsLink href="https://www.flaticon.com/" title="Flaticon">
              {" "}
              www.flaticon.com
            </CreditsLink>
          </Item>
          <Item>Web Development by Ramsey J Lewis</Item>
          <Item>Web Development by Eduardo Valencia</Item>
          <Item>Donate by Matt Brooks from the Noun Project</Item>
          <Item>Money by Max Hancock from the Noun Project</Item>
          <Item>Partner by Tomas Knopp from the Noun Project</Item>
          <Item>eco friendly by Dairy Free Design from the Noun Project</Item>
          <Item>Website Developed by Eduardo Valencia</Item>
          <Item>Website Developed by Ramsey Lewis</Item>
          <Item>Website designed by Ronit Zvi</Item>
        </ul>
      </BaseModal>
    )
  }
}

export default Modal
