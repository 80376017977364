import { createStyles, withStyles, Button } from "@material-ui/core"

const styles = () => {
  return createStyles({
    root: {
      textDecoration: "underline",
      fontSize: "0.75rem",
      padding: "0.4219rem 0.8125rem",
      color: "#212529",
      fontWeight: "normal",
    },
  })
}

export default withStyles(styles)(Button)
