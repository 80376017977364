import React, { Component } from "react"

import Credits from "./Credits"
import Social from "./Social"
import Link from "./Link"

export default class Footer extends Component {
  baseClass = "footer"

  render() {
    return (
      <footer className={this.baseClass}>
        <div className="mt-3">
          <span>
            © {new Date().getFullYear()} FishCat.org and FishCat are trade names
            of the Fishing Cat Conservancy Inc, a US-based 501c3 non-profit org.
            EIN/Tax ID 81-0894559
          </span>
        </div>
        <Credits parentClass={this.baseClass} />
        <Link href="mailto:info@fishcat.org">Media Inquiries</Link>
        <Social parentClass={this.baseClass} />
      </footer>
    )
  }
}
