import React, { Component } from "react"
import { Link } from "gatsby"

import NavLink from "./NavLink"
import { extendParentClass } from "../../../../utils/classes"
import { sectionIds } from "../../../../constants/sectionIds"
import { tabs } from "../../../../constants/tabs"
import ActivateTabButton from "../../../ActivateTabButton"
import SectionLink from "./SectionLink"

export class Links extends Component {
  baseClass = extendParentClass.bind(this)("body")

  renderLink = (linkData, index) => (
    <NavLink {...linkData} key={index} parentClass={this.baseClass} />
  )

  links = [
    {
      children: "Nature Reserves",
      id: sectionIds.ourImpact,
      className: "navSection",
    },
    {
      children: "Subscribe",
      id: sectionIds.relationshipsForLife,
      className: "navSection",
    },
    { children: "Expeditions", id: sectionIds.camps, className: "navSection" },
    {
      children: "Partner",
      tab: tabs.partner,
      className: "navSection",
    },
    {
      children: "Merch",
      id: sectionIds.merchandise,
      className: "navSection",
    },
    {
      children: "Careers",
      tab: tabs.careers,
      className: "navSection",
    },
    {
      children: "Investors",
      Link: props => <SectionLink {...props} href={sectionIds.investors} />,
      className: "navSection",
    },
    {
      children: "Annual Reports",
      Link: props => <SectionLink {...props} href={sectionIds.financials} />,
      className: "navSection",
    },
    { children: "Contact", id: "social", className: "navSection" },
  ]

  renderLinks = () => this.links.map(this.renderLink)

  render() {
    return (
      <ul className={`navbar-nav ml-auto ${this.baseClass}`}>
        {this.renderLinks()}
      </ul>
    )
  }
}

export default Links
