import React, { Component } from "react"
import ScrollButton from "../../../ScrollButton"
import { generateClasses } from "@supercoder.dev/bem-classes"
import ActivateTabButton from "../../../ActivateTabButton"
import SectionLink from "./SectionLink"

export default class NavLink extends Component {
  classes = generateClasses.bind(this)({
    name: "item",
    elements: [
      {
        name: "link",
        variants: ["active"],
      },
    ],
  })

  getClassInfo = () => {
    const { link } = this.classes.elements
    return {
      className: link.name,
      activeClassName: link.variants.active,
    }
  }

  renderScrollButton = () => {
    const { id, children } = this.props
    const classes = this.getClassInfo()
    return (
      <ScrollButton {...classes} id={id}>
        {children}
      </ScrollButton>
    )
  }

  renderLink = () => {
    const { href, children } = this.props
    const classes = this.getClassInfo()
    return (
      <SectionLink {...classes} href={href}>
        {children}
      </SectionLink>
    )
  }

  renderTab = () => {
    const { tab, children } = this.props
    const classes = this.getClassInfo()
    return <ActivateTabButton tab={tab} children={children} {...classes} />
  }

  renderLinkComponent = () => {
    const { children, Link: LinkComponent } = this.props
    const classes = this.getClassInfo()
    return <LinkComponent {...classes}>{children}</LinkComponent>
  }

  renderContent = () => {
    const { tab, href, Link: LinkComponent } = this.props
    if (LinkComponent) {
      return this.renderLinkComponent()
    } else if (tab) {
      return this.renderTab()
    } else if (href) {
      return this.renderLink()
    }
    return this.renderScrollButton()
  }

  render() {
    return <li className={this.classes.name}>{this.renderContent()}</li>
  }
}
