import { createMuiTheme } from "@material-ui/core"

const getFontFamily = () => {
  const fonts = ['"Montserrat"', "sans-serif"]
  return fonts.join(",")
}

const theme = createMuiTheme({
  typography: {
    fontFamily: getFontFamily(),
  },
  palette: {
    primary: {
      main: "#ffd86d",
      contrastText: "black",
    },
    secondary: {
      main: "rgba(3, 158, 0, 1)",
      contrastText: "white",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: "none !important",
        fontWeight: "bold",
        "&:hover": {
          color: "black",
        },
      },
    },
  },
})

theme.typography = {
  ...theme.typography,
  h1: {
    ...theme.typography.h1,
    fontWeight: 700,
    fontSize: "2.1869rem",
  },
  h2: {
    ...theme.typography.h2,
    fontSize: "1.75rem",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
  button: {
    ...theme.typography.button,
    textTransform: "none",
  },
}

export default theme
