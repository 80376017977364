import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Modal extends Component {
  classes = generateClasses({
    name: this.props.modalId,
    elements: ["title"],
  })

  render() {
    const { modalId, Header, children, footer } = this.props
    const titleId = this.classes.elements.title
    return (
      <div
        className="modal fade"
        id={modalId}
        tabindex="-1"
        role="dialog"
        aria-labelledby={modalId}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <Header titleId={titleId} />
            <div className="modal-body">{children}</div>
            {footer}
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
