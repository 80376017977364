import React, { Component } from "react"

export class Close extends Component {
  render() {
    return (
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    )
  }
}

export default Close
