/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Context from "../../Context"
import Nav from "./Nav/index"
import Footer from "./Footer"
import "../../scss/index.scss"
import { extend } from "../../utils/classes"
import SEO from "../seo"
import { tabs } from "../../constants/tabs"

const Layout = ({ children }) => {
  const baseClass = "page"
  const contentClass = extend(baseClass, "content")
  const [selectedSection, setSection] = useState(tabs.ourTeam)
  return (
    <div className={baseClass} onTouchStart={() => {}}>
      <Context.Provider value={{ selectedSection, setSection }}>
        <SEO title="Home" />
        <Nav />
        <main className={contentClass}>{children}</main>
        <Footer />
      </Context.Provider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
