export const sectionIds = {
  natureReserves: "nature-reserves",
  carbon: "carbon",
  camps: "camps",
  howWeWork: "how-we-work",
  operationalEcosystem: "operational-ecosystem",
  merchandise: "merchandise",
  reports: "impact-reports",
  globalTeam: "global-team",
  investors: "investors",
  media: "media",
  boardMembers: "board-members",
  team: "team",
  invest: "invest",
  sustainableLivelihoods: "sustainable-livelihoods",
  relationshipsForLife: "relationships-for-life",
  waysYouCanGetInvolved: "ways-you-can-get-involved",
  ourImpact: "our-impact",
  landPartnerships: "land-partnerships",
  contribution: "contribution",
  financials: "financials",
}
