const getVariant = (baseClass, variant) => `${baseClass}--${variant}`

const getVariants = (baseClass, variants) =>
  variants.map(variant => getVariant(baseClass, variant))

export const getClassWithVariants = (variants, baseClass) => {
  if (!variants || !variants.length) return baseClass
  const variantsWithBaseClasses = getVariants(baseClass, variants)
  const variantsString = variantsWithBaseClasses.join(" ")
  return `${baseClass} ${variantsString}`
}

export const getClassWithVariant = (variant, baseClass) => {
  if (variant) {
    return `${baseClass} ${baseClass}--${variant}`
  }
  return baseClass
}

export const extend = (block, element) => `${block}__${element}`

export function extendParentClass(childClass) {
  return extend(this.props.parentClass, childClass)
}

export function extendBaseClass(childClass) {
  return extend(this.baseClass, childClass)
}

export const getClassExtender = block => element => extend(block, element)
