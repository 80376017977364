import Footer from "./Modal/Footer"

import React, { Component } from "react"

export class ModalCloseFooter extends Component {
  render() {
    const { children } = this.props
    return (
      <Footer>
        <button type="button" className="btn btn-dark" data-dismiss="modal">
          Close
        </button>
        {children}
      </Footer>
    )
  }
}

export default ModalCloseFooter
