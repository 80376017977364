import React, { Component } from "react"

import Open from "./Open/index"
import Links from "./Links"
import Logo from "./Logo"
import { getClassExtender } from "../../../utils/classes"
import { closeNav } from "../../../utils/nav"
import ActivateTabButton from "../../ActivateTabButton"
import { tabs } from "../../../constants/tabs"






export default class Nav extends Component {
  static baseClass = "page-nav"
  static extendBaseClass = getClassExtender(Nav.baseClass)
  static containerClass = this.extendBaseClass("container")
  static extendContainer = getClassExtender(Nav.containerClass)
  static collapseClass = this.extendContainer("collapse")
  static callToActionButtonClass = this.extendContainer("call-to-action")
  clickListener = null

  closeNavIfOutsideClick = target => {
    const match = target.className.search(Nav.baseClass)
    const doesNotHaveNavClass = match === -1
    if (doesNotHaveNavClass) {
      closeNav()
    }
  }

  handleClick = event => {
    const { target } = event
    if (target && target.className && target.className.search) {
      this.closeNavIfOutsideClick(target)
    }
  }

  addClickListener = () => {
    this.clickListener = document.addEventListener("click", this.handleClick)
  }

  componentDidMount() {
    this.addClickListener()
  }

  componentWillUnmount() {
    if (this.clickListener) {
      this.clickListener.removeEventListener()
    }
  }

  
  render() {
    const navId = "nav"
    return (
      <nav id="navbar" className={Nav.baseClass}>
        <div className={Nav.containerClass}>
          <Logo parentClass={Nav.containerClass} />
          <a className={Nav.callToActionButtonClass} href="https://buy.stripe.com/3csbLcdZt3GU1DW4gg">Donate</a>
          <Open navId={navId} parentClass={Nav.containerClass} />
          <div
            className={`collapse navbar-collapse ${Nav.collapseClass}`}
            id={navId}
          >
            <Links parentClass={Nav.collapseClass} />
          </div>
        </div>
      </nav>
    )
  }
}
