import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Toggle extends Component {
  render() {
    const { modalId } = this.props
    const target = `#${modalId}`
    const classes = generateClasses.bind(this)({
      name: "view-credits",
    })
    return (
      <button
        type="button"
        className={classes}
        data-toggle="modal"
        data-target={target}
      >
        Website Credits
      </button>
    )
  }
}

export default Toggle
