import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
} from "@material-ui/core"

const styles = () => {
  return createStyles({})
}

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const Item = ({ classes, children }: Props) => {
  return (
    <li>
      <Typography>{children}</Typography>
    </li>
  )
}

export default withStyles(styles)(Item)
