import React, { Component } from "react"
import { sectionIds } from "../constants/sectionIds"
import Context from "../Context"
import ScrollButton from "./ScrollButton"

export class ActivateTabButton extends Component {
  static contextType = Context

  handleClick = () => {
    const { setSection } = this.context
    const { tab } = this.props
    setSection(tab)
  }

  render() {
    const {
      className,
      children,
      activeClassName,
      idToScrollTo = sectionIds.waysYouCanGetInvolved,
    } = this.props
    return (
      <ScrollButton
        id={idToScrollTo}
        onClick={this.handleClick}
        className={className}
        children={children}
        activeClassName={activeClassName}
      ></ScrollButton>
    )
  }
}

export default ActivateTabButton
