import React, { Component } from "react"
import { extend, extendBaseClass } from "../../../../utils/classes"
import Item from "./Link"
import Instagram from "./Instagram"
import Facebook from "./Facebook"
import Twitter from "./Twitter"
import Linkedin from "./Linkedin"
import Youtube from "./Youtube"
import ted from "../../../../images/ted.png"
import { extendParentClass } from "@supercoder.dev/bem-classes"

export class Social extends Component {
  baseClass = extendParentClass.bind(this)("social")
  extend = extendBaseClass.bind(this)
  iconsClass = this.extend("icons")
  tedLogo = this.extend("ted")
  tedLogoImageClass = extend(this.tedLogo, "image")

  renderItem = (itemData, index) => (
    <Item parentClass={this.iconsClass} {...itemData} key={index} />
  )

  items = [
    {
      Icon: Instagram,
      label: "Instagram",
      href: "https://www.instagram.com/fishcatorg",
    },
    {
      Icon: Facebook,
      label: "Facebook",
      href: "https://www.facebook.com/fishingcatconservancy/",
    },
    {
      Icon: Twitter,
      label: "Twitter",
      href: "https://twitter.com/fishcatorg",
    },
    {
      Icon: Linkedin,
      label: "Linkedin",
      href: "https://www.linkedin.com/company/fishing-cat-conservancy/",
    },
    {
      Icon: Youtube,
      label: "Youtube",
      href: "https://www.youtube.com/@fishcatorg",
    },
  ]

  renderItems = () => this.items.map(this.renderItem)

  render() {
    return (
      <div className={this.baseClass}>
        <ul className={this.iconsClass}>{this.renderItems()}</ul>
        <a
          href="https://www.ted.com/talks/ashwin_naidu_the_link_between_fishing_cats_and_mangrove_forest_conservation"
          className={this.tedLogo}
          id= "social"
        >
          <img src={ted} alt="TED" className={this.tedLogoImageClass} />
        </a>
      </div>
    )
  }
}

export default Social
