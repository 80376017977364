import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { extend } from "../../../utils/classes"

export default function Logo({ parentClass }) {
  const logoClass = extend(parentClass, "logo")
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "logo" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { file: image } = data
  return (
    image && (
      <Link to="/">
        <span className="sr-only">Home</span>
        <Image fluid={image.childImageSharp.fluid} className={logoClass} />
      </Link>
    )
  )
}
