import { Link } from "gatsby"
import React, { Component } from "react"
import { closeNav } from "../../../../utils/nav"

export class SectionLink extends Component {
  render() {
    const { href } = this.props
    const sectionLink = `/#${href}`
    return <Link onClick={closeNav} to={sectionLink} {...this.props}></Link>
  }
}

export default SectionLink
