import React, { Component } from "react"

export class Toggle extends Component {
  render() {
    const { navId, children, className } = this.props
    return (
      <button
        className={className}
        type="button"
        data-toggle="collapse"
        data-target={`#${navId}`}
        aria-controls={navId}
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {children}
      </button>
    )
  }
}

export default Toggle
