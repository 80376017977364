import React from "react"
import { extend } from "../../../../utils/classes"

export default function Link({ href, Icon, label, parentClass }) {
  const itemClass = extend(parentClass, "item")
  const linkClass = extend(itemClass, "link")
  const iconClass = extend(linkClass, "icon")
  return (
    <li className={itemClass}>
      <span className="sr-only">{label}</span>
      <a href={href} className={linkClass}>
        <Icon className={iconClass} />
      </a>
    </li>
  )
}
