import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Toggle from "../Toggle"
import icon from "./nav-icon.svg"

export class Open extends Component {
  classes = generateClasses.bind(this)({
    name: "toggle",
    elements: ["icon"],
  })

  render() {
    return (
      <Toggle {...this.props} className={this.classes.name}>
        <img
          alt="Toggle navigation"
          src={icon}
          className={this.classes.elements.icon}
        />
      </Toggle>
    )
  }
}

export default Open
