import React from "react"
import { ThemeProvider } from "@material-ui/core"
import { Provider } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import getStore from "./store"
import Layout from "./components/Layout"
import theme from "./theme"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY)

export const wrapPageElement = ({ element }) => {
  const store = getStore()
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap",
              },
            ],
          }}
        >
          <Layout>{element}</Layout>
        </Elements>
      </ThemeProvider>
    </Provider>
  )
}
